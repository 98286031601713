@mixin tabs-menu-theme($theme) {
  .tabs {
    .tab {
      background: map-get($main-theme-color, 500);
      color: map-get($text-color-against-main-color, 500);
      font-weight: 600;

      &.selected {
        background: map-get($border-light-color, 500);
        color: map-get($fuse-white, 500);
      }
    }
  }
}
