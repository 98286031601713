@mixin recommendations-list-theme($theme) {
  .description-item {
    border-bottom: 1px solid map-get($border-color, 500);
  }

  .fill-insurance {
    a {
      color: map-get($main-theme-color, 500);
    }
  }

  .book-actions-wrapper {
    .actions-title {
      color: map-get($main-theme-color, 500);
    }

    .actions {
      .action {
        min-width: 72px;
        min-height: 72px;
        text-align: center;
        background-color: map-get($main-theme-color, 500);
        .mat-icon {
          color: map-get($text-color-against-main-color, 500);
        }
      }
    }
  }

  .read-more {
    color: map-get($main-theme-color, 500);
  }
}
