@mixin sleep-tracker-theme($theme) {
  .sleep-tracker {
    .calendar-holder {
      .activeCellWithSleepRecord {
        background-color: map-get($calendar-active-cell-color, 500);
        color: map-get($calendar-active-cell-text-color, 500);
      }
      .emptyActiveCell {
        color: map-get($missing-day-color, 500);
        border: 1px solid map-get($missing-day-color, 500);
        background: initial;

        &.currentDate {
          background: initial;
          border: 1px solid map-get($info-background, 500);
          color: map-get($title-color, 500);
        }
      }
    }
    .error-message {
      color: map-get($error-color, 500);
    }
  }

  /* set of overrides for proper display of selected day */
  .sleep-tracker {
    .k-calendar .k-calendar-td.k-selected.k-today .k-link {
      color: inherit !important;
    }

    .k-calendar .k-calendar-td.k-selected .k-link {
      background-color: initial !important;
      color: initial !important;
      border: none !important;
      box-shadow: none !important;
    }

    .k-calendar .k-calendar-td.k-selected .dayCell {
      outline: 2px solid map-get($main-theme-color, 500);
      outline-offset: 1px;
    }

    .k-calendar-td.k-focus .k-link {
      box-shadow: none !important;
    }

    .k-calendar-td.k-selected .k-link {
      background-color: initial;
      box-shadow: none !important;
      border-radius: 12px;
    }
  }
}
