@mixin co-dashboard-theme($theme) {
  $foreground: map-get($theme, foreground);
  
  .dashboard-wrapper {
    .dashboard-header {
      .info-block {
        .info-block-title {
          color: map-get($info-text, 500);
        }
        
        .info-block-value {
          color: map-get($info-text-value, 500);
        }
      }
      
      .contact-wrapper {
        .actions {
          .action {
            min-width: 72px;
            min-height: 72px;
            text-align: center;
            background-color: map-get($main-theme-color, 500);
            .mat-icon {
              color: map-get($text-color-against-main-color, 500);
            }
          }
          
          .action-wrapper {
            .action-name {
              color: map-get($info-text, 500);
            }
          }
        }
      }
    }
    
    #columns-wrapper {
      border-top: 1px solid map-get($border-color, 500);
      .training-column {
        border-right: 2px solid map-get($border-color, 500);
      }
    }
    
    .link {
      color: map-get($main-theme-color, 500);
    }
  }
}