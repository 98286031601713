@mixin color-gradient-between-theme($theme) {
  
  .color-gradient-wrapper {
    .color-gradient {
      .display-wrapper {

        .metric-color.disabled-metric {
          background: map-get($custom-dialog-background, 500)!important;
        }
      }
    }
  }
}
