@mixin bitmovin-player-theme($theme) {
  .bitmovin-dialog-full-screen-modal {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);

    .mat-dialog-container {
      padding: 0;

      .spl-bitmovin-player-dialog {
        position: relative;
        height: 100%;

        app-bitmovin-player {
          display: block;
          height: 100%;
          overflow: hidden;

          .bitmovin-default-skin.vjs-fluid {
            height: -webkit-fill-available;
            padding-top: 0 !important;
          }

          .bitmovin-default-skin .vjs-poster img {
            max-height: 100%;
          }
        }

        .bitmovin-player-dialog__close {
          position: absolute;
          z-index: 2;
          right: 5px;
          top: 5px;
          color: map-get($main-theme-color, 500);
        }
      }
    }
  }
}
