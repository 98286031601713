@mixin saq-data-theme($theme) {

  .column-wrapper {
    .content-wrapper {
      .data-row {
        &.row-with-border {
          span {
            border-right: 1px solid map-get($border-light-color, 500);
          }
        }
      }
    }
  }
}
