@mixin register-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  register {
    #register {
      background: map-get($background-color, 500);
      
      #register-form-wrapper {
        #register-form {
          @if ($is-dark) {
            background: mat-color($fuse-navy, 600);
          } @else {
            background: map-get($background-color, card);
          }

          .submit-button {
            background-color: map-get($main-theme-color, 500);
          }

          .separator {
            color: map-get($foreground, divider);

            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }

          .form {
            .remember-forgot-password {
              .register {
                a {
                  color: map-get($main-theme-color, 500);
                }
              }
            }
            .in-take-button {
              background-color: map-get($main-theme-color, 500);
            }
          }
        }
      }

      .title-section {
        .important {
          color: map-get($main-theme-color, 500) !important;
        }
  
        h1 {
          color: map-get($info-text, 500);
        }
  
        p {
          color: map-get($info-text, 500);
        }
      }

      .go-back-button {
        color: map-get($main-theme-color, 500);
      }
  
      .separator {
        background-color: map-get($border-color, 500);
        box-shadow: 0px 1px 1px 0px map-get($border-color, 500);
      }
    }
  }
}
