@mixin select-list-menu-theme($theme) {
  .select-list-wrapper {
    .list {
      .option {
        background: map-get($panel-color, 500);
        color: map-get($main-theme-color, 500);
        
        mat-icon {
          color: map-get($info-text, 500);
        }
        
        &.selected {
          outline: 1px solid map-get($main-theme-color, 500);
          
          mat-icon {
            color: map-get($main-theme-color, 500);
          }
        }
        
        &.disabled {
          color: map-get($disable-button, 500);
        }
      }
    }
  }
}