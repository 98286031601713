@mixin login-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  login {
    #login {
      background: map-get($background-color, 500);

      #login-form-wrapper {
        #login-form {
          @if ($is-dark) {
            background: mat-color($fuse-navy, 600);
          } @else {
            background: map-get($background-color, card);
          }

          .submit-button {
            background-color: map-get($main-theme-color, 500) !important;
            color: map-get($fuse-black, 500) !important;

            &.button-in-progress {
              background-color: map-get($disable-button, 500) !important;
              color: map-get($fuse-white, 500) !important;
            }
          }

          .separator {
            color: map-get($foreground, divider);

            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }

          .register {
            a {
              color: map-get($main-theme-color, 500);
            }
          }

          .forgot-password-link {
            color: map-get($main-theme-color, 500);
          }

          .google-login {
            a {
              color: map-get($fuse-white, 500);
            }
          }

          .facebook-login {
            a {
              color: map-get($fuse-white, 500);
            }
          }

          .login-error {
            .error-text {
              color: map-get($error-color, 500);
            }
          }

          .separator {
            background-color: map-get($border-color, 500);
            box-shadow: 0px 1px 1px 0px map-get($border-color, 500);
          }

          .version-container {
            font-size: x-small;
          }
        }
      }
    }
  }
}
