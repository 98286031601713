@mixin notes-info-theme($theme) {
  .icon-container {
    color: map-get($info-message-color, 500);
    
    .back {
      background-color: map-get($info-background, 500);
    }
    
    .is-new {
      background-color: map-get($counter-background, 500);
    }
  }
}
