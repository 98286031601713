@mixin links-menu-theme($theme) {
  .links-wrapper {
    .link {
      background: map-get($panel-color, 500);
      color: map-get($main-theme-color, 500);
      
      .link-icon {
        color: map-get($fuse-white, 500);
        
        &.skip-icon {
          background-color: map-get($error-color, 500);
        }
        
        &.success-icon {
          background-color: map-get($main-theme-color, 500);
        }
        
        &.info-icon {
          color: map-get($info-background, 500);
        }
        
        &.started-icon {
          color: map-get($info-icon, 500);
        }
      }
      
      .sub-title {
        color: map-get($info-text-value, 500);
      }
    }

    .v-splitter {
      color: map-get($border-light-color, 500);
    }
  }
}
