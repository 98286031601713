@mixin force-development-theme($theme) {

  .vertical-jump-info {
    .score-wrapper {
      .score-section {
        .score-title {
          color: map-get($info-text, 500);
        }

        .score-value {
          &.score {
            color: map-get($main-theme-color, 500);
          }

          &.best {
            color: map-get($text-accent, 500);
          }
        }
      }
    }
  }
}
