@mixin testing-assessment-history-theme($theme) {

  .testing-assessments-wrapper {
    .testing-assessments-header {
      .testing-assessments-info-wrapper {
        .info-block {
          .info-block-title {
            color: map-get($info-text, 500);
          }

          .info-block-value {
            color: map-get($title-color, 500);
          }
        }
      }
    }
  }

}
