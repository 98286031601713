// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

// Shared Application styles
.splash {
  background: url('/assets/images/logos/splash.svg') no-repeat center black;
  min-width: 384px;
  height: 100%;
}

.submit-button {
  height: 40px;
  min-width: 102px;
  margin: 16px auto !important;
  display: block !important;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0.5);
  border-radius: 4px !important;

  &.pulse-animation {
    animation: pulse 1.5s infinite;
    animation-delay: 4s;
    -webkit-animation-delay: 4s;

    &:hover {
      animation: none;
    }
  }
}

.spl-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px 7px;
  width: fit-content;
  height: 40px;
  background-color: map-get($main-theme-color, 500) !important;
  border-radius: 4px;

  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  color: map-get($text-color-against-main-color, 500) !important;

  &.mat-button-disabled {
    opacity: 0.3 !important;
  }

  &.full {
    width: 100%;
  }

  &.center {
    display: flex;
    margin: auto;
  }

  &.pulse-animation {
    box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0.5);
    animation: pulse 1.5s infinite;
    animation-delay: 4s;
    -webkit-animation-delay: 4s;

    &:hover {
      animation: none;
    }
  }

  mat-spinner {
    display: inline-flex;
    margin-left: 7px;
  }
}

.spl-button-full-mobile {
  @extend .spl-button;
  width: 100% !important;
}

.spl-outline-button {
  @extend .spl-button;
  background: none !important;
  border: 1px solid map-get($main-theme-color, 500) !important;
  color: map-get($main-theme-color, 500) !important;
}

.spl-outline-button-full-mobile {
  @extend .spl-outline-button;
  width: 100% !important;
}

.small-spl-button {
  @extend .spl-button;
  height: 30px !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.spl-text-button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  width: fit-content;
  color: map-get($main-theme-color, 500);
  cursor: pointer;
}

.spl-content-wrapper {
  padding: 48px;
  max-width: 1440px;
  width: 100%;
}

.spl-select-wrapper {
  display: flex;
  flex-direction: column;

  .spl-select-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .spl-select {
    width: 150px;
    height: 30px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    color: map-get($fuse-black, 500);
    background-color: map-get($input-background, 500);

    box-shadow: 0 0 0 1px map-get($main-theme-color, 500);
    border: none;
    border-radius: 4px;

    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-padding-start: 8px;
    -moz-padding-start: 8px;
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    background-image: url(~src/assets/images/icons/select-arrow.svg);
    background-position: center right;
    background-repeat: no-repeat;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.spl-input-required {
  box-shadow: 0 0 0 2px map-get($error-color, 500) !important;
  -webkit-appearance: none;
}

.spl-switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: start;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;

  .right {
    text-align: right;
    margin-right: 12px;
  }

  .left {
    text-align: left;
    margin-left: 12px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 69px;
    height: 32px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($panel-color, 500);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 32px;
    width: 32px;
    left: 0;
    bottom: 0;
    background-color: map-get($main-theme-color, 500);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: map-get($panel-color, 500);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px map-get($border-color, 500);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.spl-info-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: map-get($info-text, 500);

  .text-accent {
    color: map-get($title-color, 500);
  }
}

.big-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 20px;
  text-transform: capitalize;
  color: map-get($title-color, 500);
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: map-get($title-color, 500);
}

.small-title {
  @extend .title;
  font-size: 18px;
  line-height: 22px;
}

.solo-column {
  max-width: 480px;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.solo-full-column {
  max-width: 1440px;
  width: 100%;
  padding: 48px;
}

.splitter {
  width: 100%;
  border: 1px solid map-get($border-color, 500);
  background: map-get($panel-color, 500);
}

@include media-breakpoint('xs') {
  .solo-column,
  .solo-full-column {
    padding: 20px !important;
  }

  .confirmed-block {
    width: 100% !important;
  }
}

@include media-breakpoint('gt-xs') {
  .spl-button-full-mobile,
  .spl-outline-button-full-mobile {
    width: fit-content !important;
  }
}

@media (max-width: 1400px) {
  .spl-content-wrapper {
    padding: 20px;
  }
}

.spl-scroll-container {
  overflow-y: auto;
}

.confirmed-block {
  display: flex;
  padding: 20px 25px;
  width: 335px;
  border-radius: 3px;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  background: map-get($success-message, 500);
  color: map-get($fuse-white, 500);
}

@keyframes pulse {
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(map-get($main-theme-color, 500), 0);
    box-shadow: 0 0 0 20px rgba(map-get($main-theme-color, 500), 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0);
    box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0);
  }
}

* {
  font-family: Poppins, sans-serif;
}

ngx-charts-line-chart,
ngx-charts-chart {
  text {
    fill: map-get($fuse-white, 500) !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
  }

  .legend-wrap {
    .legend-item {
      &:hover {
        color: map-get($fuse-white, 500);
      }
    }

    .legend-label {
      &:hover {
        color: map-get($fuse-white, 500) !important;
      }

      .active {
        .legend-label-text {
          color: map-get($fuse-white, 500) !important;
        }
      }
    }

    .scale-legend-label {
      color: map-get($fuse-white, 500);
    }
  }

  g.line-chart > g:last-of-type > g g.line-series > path {
    stroke-width: 4;
  }

  g.line-chart > g:last-of-type > g:nth-child(3) g.line-series > path {
    stroke-dasharray: 8;
  }
}

in-take-field {
  mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 2em;
    }

    .mat-form-field-subscript-wrapper {
      font-size: 75%;
      margin-top: 0.6666666667em;
      top: calc(100% - 2.8em);
    }
  }
}

.spinner-wrapper {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: map-get($main-theme-color, 500) !important;
  }
}

.cdk-overlay-backdrop {
  opacity: 0.1 !important;
}

.cdk-overlay-backdrop.custom-dialog {
  opacity: 1 !important;
  background: inherit !important;
  backdrop-filter: blur(2px) !important;
}

body:not(.is-mobile) {
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 12px rgba(70, 70, 70, 0.8) !important;
  }
}

.spl-video-wrapper {
  width: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    flex: 1 1 auto;
  }
  video {
    width: 100%;
  }
  .video-wrapper__no-video {
    font-size: 16px;
    color: #727272;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.ios {
  .is-mobile {
    .spl-layout-container__ios-fix {
      display: block !important;
    }
  }
}

.is-mobile {
  .video-wrapper__no-video {
    height: 210px;
  }
}

.is-desktop {
  .video-wrapper__no-video {
    height: 270px;
  }
}

.skeleton-wrapper {
  display: flex;
  flex-direction: column;

  .skeleton-loader {
    width: 100%;
    background-color: rgb(70, 70, 70);
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
  }

  .skeleton-loader-header {
    width: 50%;
    margin-bottom: 20px;
  }

  .skeleton-loader-training-session-header {
    height: 50px;
    width: 330px;
  }

  .skeleton-loader-training-session-sub-header {
    width: 280px;
  }

  .skeleton-loader-assessment-header-title {
    height: 50px;
    margin-bottom: 25px;
  }

  .skeleton-loader-assessment-header-chart {
    width: 170px;
    height: 170px;
    margin: auto;
    border-radius: 50%;
  }

  .skeleton-info-block-wrapper {
    width: 105px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .skeleton-loader {
      height: 32px;
      margin-bottom: 8px;
    }

    .skeleton-loader-block {
      width: 28px;
      height: 36px;
    }
  }

  .skeleton-loader:before {
    background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.6),
      hsla(0, 0%, 100%, 0)
    );

    animation: shimmer 2s ease-in-out infinite;
    transition-timing-function: ease-in-out;
    background-size: 200px 100%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 200px;
    z-index: 1;
  }

  @keyframes shimmer {
    0% {
      left: -200px;
    }
    100% {
      left: calc(100vw + 200px);
    }
  }
}

.hide-zendesk {
  display: none !important;
}

.zendesk-ios-safe-area {
  padding-top: env(safe-area-inset-top);
}


.mat-accent .mat-slider-track-fill {
  background-color: red !important;
}

.mat-slider-thumb-label {
  background-color: red !important;
  
}

.mat-slider-thumb {
  background-color: red !important;
}

::ng-deep .mat-legacy-slider .mat-legacy-slider-track-fill {
  background-color: #3f51b5 !important; /* цвет заполненной части */
}

::ng-deep .mat-legacy-slider .mat-legacy-slider-thumb {
  background-color: #3f51b5 !important; /* цвет бегунка */
}

::ng-deep .mat-legacy-slider .mat-legacy-slider-track-background {
  background-color: #e0e0e0 !important; /* цвет незаполненной части */
}