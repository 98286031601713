@mixin select-profile-theme($theme) {
  select-profile {
    h1 {
      color: map-get($info-text, 500);
    }

    p {
      color: map-get($info-text, 500);
    }

    .mat-selection-list-wrapper {
      .mat-list-single-selected-option {
        background-color: map-get(
          $select-profile-option-background,
          500
        ) !important;
        border: 1px solid map-get($main-theme-color, 500);
        box-sizing: content-box;
      }
    }

    .delete-account {
      color: map-get($error-color, 500) !important;
      border-color: map-get($error-color, 500) !important;
    }
  }
}
