@mixin in-studio-training-theme($theme) {
  $foreground: map-get($theme, foreground);

  .columns-wrapper {
    .training-column {
      .schedule-wrapper {
        .upcoming-training {
          color: map-get($upcoming-training, 500);
        }

        .pre-training-tip {
          background: map-get($info-message-background, 500);
          color: map-get($info-message-color, 500);
          .title {
            color: map-get($info-message-color, 500);
          }
        }
      }

      .history-wrapper {
        .history {
          .history-sort {
            color: map-get($main-theme-color, 500);
          }
        }
      }
    }
  }
}
