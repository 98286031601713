@mixin spl-countdown-theme($theme) {
  .countdown {
    background: map-get($countdown-background, 500);
    
    .time {
      color: map-get($main-theme-color, 500);
    }

    .title {
      color: map-get($title-color, 500);
    }

    .unit-time {
      color: map-get($info-text, 500);
    }

    .time-splitter {
      color: map-get($main-theme-color, 500);
    }
  }

  .showGetStartedMessage {
    background-color: map-get($info-message-background, 500);
    color: map-get($fuse-white, 500);
  }
}
