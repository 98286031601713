@mixin in-studio-exercise-theme($theme) {
  .spl-custom-select {
    .spl-custom-select-input {
      background-color: map-get($input-background, 500);
      color: map-get($fuse-black, 500);
      box-shadow: 0 0 0 1px map-get($main-theme-color, 500);
    }

    .spl-custom-select-input::placeholder {
      color: map-get($fuse-black, 500) !important;
    }
  }

  .spl-custom-select-menu {
    background: map-get($fuse-white, 500) !important;

    .spl-custom-select-option {
      color: map-get($fuse-black, 500) !important;
    }

    .spl-custom-select-option:not(.mat-active):not(:hover) {
      background: map-get($fuse-white, 500) !important;
    }
  }
  
  .reset-values-button {
    color: map-get($main-theme-color, 500) !important;
  }
  
  .incomplete-wrapper {
    .incomplete {
      color: map-get($error-color, 500);
    }
  }
  
  .recommendations-wrapper {
    background-color: map-get($custom-dialog-background, 500);
  }
}
