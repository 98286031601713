@mixin reschedule-in-studio-training-theme($theme) {

  .single-session-wrapper {
    .selected-time {
      color: map-get($title-color, 500);
    }
    
    .back-button {
      color: map-get($main-theme-color, 500);
    }
  }
}
