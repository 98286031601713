@mixin spl-navigation-theme($theme) {
  spl-navigation {

    .profile-info {
      color: map-get($profile-info-color, 500);
    }

    .nav-link {
      &.accent {
        background-color: map-get($main-theme-color, 500) !important;
        color: map-get($text-color-against-main-color, 500) !important;
      }

      .coming-soon {
        color: map-get($disable-button, 500);
      }
    }
  }
}
