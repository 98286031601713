@mixin color-gradient-theme($theme) {
  
  .color-gradient-wrapper {
    .color-gradient {
      .display-wrapper {

        .mark {
          background: map-get($fuse-white, 500);
          border: 2px solid map-get($fuse-black, 500);

          .mark-level {
            color: map-get($fuse-white, 500);
          }
        }
        
        .display {
          background-color: map-get($custom-dialog-background, 500);
        }
      }

      .value-wrapper {
        color: map-get($fuse-black, 500);
      }
    }
  }
}
