@mixin in-studio-schedule-theme($theme) {
  .schedule-wrapper {
    .upcoming-training {
      .date {
        color: map-get($info-text, 500);
      }
    }
    
    .pre-training-tip {
      color: map-get($title-color, 500);
    }
  }
}
