@mixin in-take-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  in-take {
    #in-take-flow-host {
  
      h1 {
        color: map-get($info-text, 500);
      }
  
      p {
        color: map-get($info-text, 500);
      }
      
      in-take-flow {
        in-take-section {
          @if ($is-dark) {
            background: mat-color($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .go-back-button {
            color: map-get($main-theme-color, 500) !important;
          }

          .separator {
            box-shadow: 0px 1px 1px 0px map-get($border-light-color, 500);
            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }
        }
      }
    }
  }

  .submit-button {
    background-color: map-get($main-theme-color, 500) !important;
  }
}
