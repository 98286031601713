@mixin reset-password-theme($theme) {
  $background: map-get($theme, background);
  $is-dark: map-get($theme, is-dark);

  reset-password {
    background: map-get($background-color, 500);;
    #reset-password {
      #reset-password-form-wrapper {
        #reset-password-form {
          @if ($is-dark) {
            background: mat-color($fuse-navy, 600);
          } @else {
            background: map-get($background-color, 500);
          }
        }
      }
    }
  }
}
