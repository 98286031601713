@mixin forgot-password-theme($theme) {
  $background: map-get($theme, background);
  $is-dark: map-get($theme, is-dark);

  forgot-password {
    #forgot-password {
      #forgot-password-form-wrapper {
        #forgot-password-form {
          @if ($is-dark) {
            background: mat-color($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .login {
            mat-icon {
              color: map-get($main-theme-color, 500) !important;
            }

            .link {
              color: map-get($main-theme-color, 500) !important;
            }
          }

          .splitter {
            border: 1px solid map-get($border-color, 500);
          }
        }

        .check-email {
          .logo {
            mat-icon {
              color: map-get($main-theme-color, 500) !important;
            }
          }
        }
      }
    }
  }
}
