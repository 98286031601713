@mixin in-studio-training-session-theme($theme) {
  .session-wrapper {
    .exercise-wrapper {
      .results-wrapper {
        background: map-get($panel-color, 500);

        .table-body {
          input {
            background-color: map-get($input-background, 500);
            color: map-get($border-light-color, 500);
          }
        }
      }
    }

    .rate-station-wrapper {
      .rate {
        .rate-scale {
          .rate-item {
            .rate-value {
              color: map-get($title-color, 500);
            }
            .rate-color {
              background: map-get($main-theme-color, 500);
              box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            &.selected {
              .rate-color {
                border: 2px solid map-get($title-color, 500);
                .arrow-bot {
                  border-bottom: 2px solid map-get($title-color, 500);
                  border-left: 2px solid map-get($title-color, 500);
                }
              }
            }
          }
        }
      }
    }

    .rotate-station-wrapper {
      .goal-wrapper {
        background: map-get($info-message-background, 500);
        color: map-get($info-message-color, 500);
        .title {
          color: map-get($info-message-color, 500);
        }
      }

      .stations-wrapper {
        color: map-get($fuse-white, 500);

        .station {
          background: linear-gradient(180deg, rgba(201, 55, 41, 0.2) 0%, rgba(30, 3, 0, 0.2) 100%), map-get($main-theme-color, 500);
        }
      }
    }

    .select-station-wrapper {
      .stations-wrapper {
        color: map-get($fuse-white, 500);
        .station {
          background: linear-gradient(180deg, rgba(201, 55, 41, 0.2) 0%, rgba(30, 3, 0, 0.2) 100%), map-get($main-theme-color, 500);
        }
      }
    }

    .station-wrapper {
      .title-wrapper {
        .tier-wrapper {
          border: 1px solid map-get($fuse-white, 500);
        }
      }
    }
  }
  
  .complete-session-wrapper {
    .sections-wrapper {
      .section {
        .info-block {
          .info-title {
            color: map-get($info-text, 500);
          }
        }
      }
      
      .section:first-child {
        border-bottom: 1px solid map-get($border-color, 500);
      }
    }
  }
}
