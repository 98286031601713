@mixin spl-chart-gauge-theme($theme) {

  .gauge-chart-wrapper {
    .custom-legend {
      .legend-item {
        .legend-title {
          color: map-get($info-text, 500);
        }
      }
    }
  }
}
