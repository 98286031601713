@mixin buttons-menu-theme($theme) {
  .buttons-menu-wrapper {
    .menu-option {
      background: map-get($main-theme-color, 500);
      color: map-get($text-color-against-main-color, 500);

      &.selected {
        background: map-get($border-light-color, 500);
        color: map-get($fuse-white , 500);
      }

      &.disabled {
        background: map-get($panel-color, 500);
        color: map-get($info-text, 500) !important;
      }
    }
  }
}
