@mixin navbar-theme($theme) {

  navbar {
    background: map-get($fuse-black, 500);
    .navbar-top {
      background: map-get($fuse-black, 500) !important;
      .spl-red-color {
        color: map-get($main-theme-color, 500);
      }
    }
  }
}
