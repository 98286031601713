@mixin saq-summary-theme($theme) {

  .column-wrapper {
    .content-wrapper {
      .metrics-wrapper {
        .metrics-column {
          .metric-value {
            .metric-notice {
              color: map-get($info-text, 500);
            }
          }
        }
      }
    }
  }
}
