@mixin custom-dialog-theme($theme) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: map-get($fuse-black, 500);
  }

  .custom-dialog-panel {
    max-width: 450px !important;
    width: 80vw;
  }

  .mat-dialog-container {
    background: map-get($custom-dialog-background, 500);
  }
}
