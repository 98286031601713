@mixin info-panel-theme($theme) {
  .info-blocks-wrapper {
    .info-blocks {
      color: map-get($title-color, 500);
      background: map-get($panel-color, 500);

      .info-block {
        border-right: 1px solid map-get($border-light-color, 500);
      }

      .info-block:last-child {
        border-right: none;
      }
    }
  }
}
