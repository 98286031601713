@mixin assessments-theme($theme) {
  .section-wrapper {
    .read-more {
      color: map-get($main-theme-color, 500);
    }

    .columns-wrapper {
      border-top: 1px solid map-get($border-color, 500);

      .column {
        border-right: 2px solid map-get($border-color, 500);
      }

      .column-wrapper {
        .content-wrapper {
          .data-row {
            .row-value {
              &.bad-result {
                color: map-get($assessment-bad-result, 500);
              }
            }

            &.notice {
              color: map-get($notice-text, 500);
            }

            .red-notice {
              color: map-get($text-accent, 500);
            }
          }

          .data-row-notice {
            color: map-get($notice-text, 500);
          }

          .small-splitter {
            border-top: 1px solid map-get($border-color, 500) !important;
          }
        }
      }
    }
  }

  .icon-container {
    color: map-get($info-message-color, 500);

    .back {
      background-color: map-get($info-background, 500);
    }
  }
  
  .disabled-title {
    opacity: 0.5;
  }
}
