@mixin training-theme($theme) {
  $foreground: map-get($theme, foreground);

  .training-wrapper {
    .mat-tab-group {
      .mat-tab-header {
        .mat-tab-label {
          color: map-get($main-theme-color, 500);
        }

        .mat-tab-label-active {
          color: map-get($title-color, 500);
        }
      }

      .mat-ink-bar {
        background-color: map-get($border-color, 500) !important;
      }
    }
  }
  
  .columns-wrapper {
    border-top: 1px solid map-get($border-color, 500);
    
    .training-column {
      border-right: 2px solid map-get($border-color, 500);
    }
  }

  .dashboard-training-wrapper {
    .chat-link {
      color: map-get($main-theme-color, 500);
    }
  }
}

.right-training-padding {
  padding-right: 20px;
}

.left-training-padding {
  padding-left: 20px;
}

@media (max-width: 1400px) {
  .right-training-padding {
    padding-right: 15px !important;
  }

  .left-training-padding {
    padding-left: 15px !important;
  }
}

@include media-breakpoint('xs') {
  .right-training-padding {
    padding-right: 0px !important;
  }

  .left-training-padding {
    padding-left: 0px !important;
  }
}

.station-wrapper {
  display: flex;
  flex-direction: column;

  .title-wrapper {
    display: flex;
    align-items: center;

    .tier-wrapper {
      margin-left: 16px;

      width: 38px;
      height: 38px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
    }
  }

  .exercises-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    width: calc(100% + 60px);

    .exercise {
      padding-bottom: 28px;
    }
  }
}

@include media-breakpoint('xs') {
  .big-title {
    display: none;
  }

  .title-wrapper {
    justify-content: space-between;

    .tier-wrapper {
      margin-left: 0 !important;
    }
  }

  .exercises-wrapper {
    display: flex;
    margin-top: 28px;
    width: 100% !important;

    .exercise {
      border-bottom: 1px solid #1e1e23;
      padding: 28px 0;
      width: 100%;
    }

    .exercise:first-child {
      padding: 0 0 28px 0;
    }

    .exercise:last-child {
      border-bottom: none;
    }
  }
}
