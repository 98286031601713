// -----------------------------------------------------------------------------------------------------
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {
  // Boxed
  &.boxed {
    max-width: 1200px;
    margin: 0 auto;

    @include mat-elevation(8);
  }
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }

  // Remove focus outline
  &:focus {
    outline: none;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
  border: none;
  padding: 0 16px;
}
