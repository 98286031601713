@mixin fuse-theme-options-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  fuse-theme-options {
    .theme-options-panel {
      form {
        .group {
          border: 1px solid map-get($foreground, divider);

          h2 {
            background: map-get($background, background);
            color: map-get($foreground, secondary-text);
          }

          h3 {
            color: map-get($foreground, secondary-text);
          }
        }
      }
    }
  }
}
