@mixin exercise-info-theme($theme) {
  .info-table-wrapper {
    .info-block {
      .info-title {
        color: map-get($info-text, 500);
      }
    }

    .info-table {
      background: map-get($panel-color, 500);
    }
  }
}
