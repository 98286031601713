@mixin spl-notification-theme($theme) {
  .mat-snack-bar-container {
    &.custom-snackbar {
      background-color: map-get($info-message-background, 500) !important;
      color: white !important;
      font-size: 13px !important;
      line-height: 19px !important;
      text-align: center;
      padding: 0 !important;
      min-height: 0 !important;
      margin: 0px !important;
      border-radius: 0px !important;
      width: 100vw !important;
      max-width: 100vw !important;
    }
  }
  .cdk-overlay-container {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane.mat-snack-bar-handset {
        padding-top: env(safe-area-inset-top);
        background-color: map-get($info-message-background, 500);
      }
    }
  }
}
