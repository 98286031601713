@mixin schedule-in-studio-recurring-session-theme($theme) {

  .reccuring-session-wrapper {
    .selected-time {
      color: map-get($title-color, 500);
    }

    .back-button {
      color: map-get($main-theme-color, 500);
    }
  }
}
