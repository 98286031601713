@mixin hydration-tracker-theme($theme) {
  .hydration-tracker-wrapper {
    .calendar-holder {
      border-bottom: 1px solid map-get($border-light-color, 500);
      
      .k-calendar .k-calendar-td.k-selected .k-link {
        background-color: initial !important;
      }
      
      .spinner-wrapper {
        background-color: map-get($background-color-transparent, 500);
      }
      
      .hasRecordCell {
        color: map-get($black-color, 500);
      }

      .futureCell {
        color: map-get($calendar-cell-color, 500);
        border: 1px solid map-get($calendar-cell-color, 500);
      }

      .missingCell {
        border: 1px solid map-get($missing-day-color, 500);
        color: map-get($missing-day-color, 500);

        &.currentDate {
          background: initial;
          border: 1px solid map-get($info-background, 500);
          color: map-get($title-color, 500);
        }
      }
      
      .k-calendar-td.k-focus .k-link {
        box-shadow: none;
      }
      
      .k-calendar-td.k-focus .k-link .dayCell {
        outline: 2px solid map-get($main-theme-color, 500);
        outline-offset: 1px;
      }
    }
    
    .inputs-wrapper {
      .input-section {
        border: 1px solid map-get($border-light-color, 500);
        background-color: map-get($panel-color, 500);
        
        .input {
          .urine-level {
            border: 1px solid map-get($border-light-color, 500);
            
            &.selected {
              border: 2px solid map-get($main-theme-color, 700);
            }
          }
        }
      }
    }
  }
}
