@mixin in-take-completion-theme($theme) {
  in-take-completion {
    h1 {
      color: map-get($info-text, 500);
    }
    
    p {
      color: map-get($info-text, 500);
    }
  }
  
}
