@mixin buttons-list-menu-theme($theme) {
  .list {
    .option {
      background: map-get($panel-color, 500);
      color: map-get($main-theme-color, 500);
      
      &.disabled {
        color: map-get($disable-button, 500);
      }
      
      &.inactive {
        color: map-get($info-text-value, 500);
      }
    }
  }
}
