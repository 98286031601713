@mixin spl-line-chart-theme($theme) {
  
  .line-chart-wrapper {
    .line-chart {
      .score {
        color: map-get($fuse-white, 500);
      }
    }
  }

  .ngx-charts {
    .gridline-path {
      stroke: map-get($border-light-color, 500) !important;
    }
  }
}
