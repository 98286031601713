@mixin exercise-program-theme($theme) {
  
  .exercise-program-wrapper {
    
    .completeness {
      border: 1px solid map-get($border-color, 500);
      color: map-get($fuse-black, 500);
      background-color: map-get($fuse-white, 500);
      
      .completeness-progress {
        left: 0;
        background-color: map-get($main-theme-color, 500);
      }
    }
  }
}
